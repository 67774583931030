import React, { createContext } from "react";
import i18n from "i18next";
import localforage from "localforage";

export const LanguageContext = createContext({
  changeLanguage: (lng = "ar") => {
    i18n.changeLanguage(lng);
  },
  language: "ar",
});

// Create a provider component
export function LanguageProvider({ children }) {
  const [language, setLanguage] = React.useState("ar");

  React.useEffect(() => {
    const loadLanguage = async () => {
      try {
        const savedLanguage = (await localforage?.getItem("language")) || "ar";
        setLanguage(savedLanguage);
        i18n.changeLanguage(savedLanguage);
      } catch (error) {
        console.error(error);
      }
    };

    loadLanguage();
  }, []);

  React.useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const changeLanguage = async (lng) => {
    try {
      setLanguage(lng);
      await localforage?.setItem("language", lng); // Save the selected language to local storage
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
