const logEventName = {
  operatorSelected: "operator_selected",
  phoneNumberSubmitted: "phone_number_submitted",
  resendOtp: "resend_otp",
  otpSubmitted: "otp_submitted",
  webviewClosed: "webview_closed",
  paymentFinished: "payment_finished",
  backClicked: "back_clicked",
  phoneNumberChanged: "phone_number_changed",
  phoneNumberChecked: "phone_number_checked",
  packagesRequested: "packages_requested",
  operatorsRequested: "operators_requested",
  packageSelected: "package_selected",
  finishActivationPressed: "finish_activation_pressed",
  finishSubscribePressed: "finish_subscribe_pressed",
  goToAppsStorePressed: "go_to_apps_store_pressed",
  deactivateLaterPressed: "deactivate_later_pressed",
  fieldInputted: "field_inputted",
  cardsRequested: "cards_requested",
  countryCodeRequested: "country_code_requested",
  customerDetailsRequested: "customer_details_requested",
  paymentMethodInformationSubmitted: "payment_method_information_submitted",
  creditCardDeleted: "credit_card_deleted",
  screenView: "screen_view"
};

export default logEventName;
