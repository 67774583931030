import * as React from "react";

import { rudderInitialize } from "./rudderInitialize";

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ChakraProvider, Spinner } from "@chakra-ui/react";

import theme from "./theme";

import Home from "./pages/home";
import { LanguageProvider } from "./context/language-context";

const TapChoosePackage = React.lazy(() =>
  import("./pages/tap-payment/choose-package")
);
const TapSubscribe = React.lazy(() => import("./pages/tap-payment/subscribe"));
const TapCancelSub = React.lazy(() => import("./pages/tap-payment/cancel-sub"));
const TapFinish = React.lazy(() => import("./pages/tap-payment/finish"));
const MondiaInputNumber = React.lazy(() =>
  import("./pages/mondia-payment/input-number")
);
const MondiaChoosePackage = React.lazy(() =>
  import("./pages/mondia-payment/choose-package")
);
const MondiaInputVerificationCode = React.lazy(() =>
  import("./pages/mondia-payment/input-verification-code")
);
const MondiaSuccess = React.lazy(() =>
  import("./pages/mondia-payment/success")
);
const MobilyHome = React.lazy(() => import("./pages/mobily/home/index"));
const MobilyInvoice = React.lazy(() => import("./pages/mobily/invoice/index"));
const MobilyVerify = React.lazy(() => import("./pages/mobily/verify/index"));
const MobilySuccess = React.lazy(() => import("./pages/mobily/success"))

const ProtectedSuccessPage = ({ redirectPath, children }) => {
  const location = useLocation();

  if (!location.state?.status) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

function App() {
  React.useEffect(() => {
    rudderInitialize();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <LanguageProvider>
        <BrowserRouter basename="/payment">
          <React.Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="tap-payment">
                <Route path="finish" element={<TapFinish />} />
                <Route path="subscribe/cancel" element={<TapCancelSub />} />
                <Route path="choose-package" element={<TapChoosePackage />} />
                {["subscribe", "add-card"].map((path) => (
                  <Route path={path} element={<TapSubscribe />} key={path} />
                ))}
              </Route>
              <Route path="mondia-payment">
                <Route
                  path="success"
                  element={
                    <ProtectedSuccessPage redirectPath="/mondia-payment/input-number">
                      <MondiaSuccess />
                    </ProtectedSuccessPage>
                  }
                />
                <Route
                  path="input-verification-code"
                  element={<MondiaInputVerificationCode />}
                />
                <Route path="choose-package" element={<MondiaChoosePackage />} />
                <Route path="input-number" element={<MondiaInputNumber />} />
              </Route>
              <Route path="mobily">
                <Route path="" element={<MobilyHome />} />
                <Route path="invoice/:id" element={<MobilyInvoice />} />
                <Route path="verify" element={<MobilyVerify />} /> 
                <Route path="success" element={<MobilySuccess />} /> 
              </Route>
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </LanguageProvider>
    </ChakraProvider>
  );
}

export default App;
