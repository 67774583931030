import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enErrors from "./locales/en/translations/errors.json";
import arErrors from "./locales/ar/translations/errors.json";
import trErrors from "./locales/tr/translations/errors.json";

import enPages from "./locales/en/translations/pages.json";
import arPages from "./locales/ar/translations/pages.json";
import trPages from "./locales/tr/translations/pages.json";

import enComponents from "./locales/en/translations/components.json";
import arComponents from "./locales/ar/translations/components.json";
import trComponents from "./locales/tr/translations/components.json";

import enSections from "./locales/en/translations/sections.json";
import arSections from "./locales/ar/translations/sections.json";
import trSections from "./locales/tr/translations/sections.json";

const resources = {
  en: {
    pages: {
      ...enPages,
    },
    components: {
      ...enComponents,
    },
    sections: {
      ...enSections,
    },
    errors: {
      ...enErrors,
    },
  },
  ar: {
    pages: {
      ...arPages,
    },
    components: {
      ...arComponents,
    },
    sections: {
      ...arSections,
    },
    errors: {
      ...arErrors,
    },
  },
  tr: {
    pages: {
      ...trPages,
    },
    components: {
      ...trComponents,
    },
    sections: {
      ...trSections,
    },
    errors: {
      ...trErrors,
    },
  },
};

i18n.use(initReactI18next).init({
  lng: "ar",
  fallbackLng: "en",
  resources,
  interpolation: {
    escapeValue: false,
  },
  keySeparator: ".", // Use a period as the default key separator
  nsSeparator: ":", // Use a colon as the namespace separator
  // debug: true,
});

export default i18n;
